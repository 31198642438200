import './App.css';
import React from "react";
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import FaceLiveness from './Components/FaceLiveness';
import ReferenceImage from './Components/ReferenceImage';
import {
  View,
  Card,
  Flex,
  useTheme,
} from '@aws-amplify/ui-react';

import awsexports from './aws-exports';

Amplify.configure(awsexports);

function App() {

  const [faceLivenessAnalysis, setFaceLivenessAnalysis] = React.useState(null)

  const getfaceLivenessAnalysis = (faceLivenessAnalysis) => {
    if (faceLivenessAnalysis !== null) {
      setFaceLivenessAnalysis(faceLivenessAnalysis)
    }
  }

  const tryagain = () =>{
    setFaceLivenessAnalysis(null)
  }

  const theme = {
    name: 'custom-theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            '10': '#e5f5ee', // Lightest shade
            '20': '#bfe4d4', // Lighter shade
            '40': '#80c4a6', // Light shade
            '60': '#007d53', // Main color
            '80': '#006947', // Darker shade
            '100': '#004c32', // Darkest shad
          },
        },
      },
      components: {
        button: {
          primary: {
            backgroundColor: { value: '{colors.brand.primary.60}' }, // Use the main primary color
            color: { value: '#ffffff' }, // Text color
            _hover: {
              backgroundColor: { value: '{colors.brand.primary.80}' }, // Darker shade on hover
            },
            _focus: {
              backgroundColor: { value: '{colors.brand.primary.80}' }, // Darker shade on focus
            },
          },
        },
        loader: {
          color: { value: '#007d53' }, // Change the loader color to your desired color
        },
      },
    },
  };

  const { tokens } = useTheme();
  return (
    <ThemeProvider theme={theme}>
      
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
        >
          <View 
            as="section"
            maxWidth="740px"
            width="100%">
              <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <Card
                  backgroundColor='#ffffff'
                  padding="15px"
                >
        
                <View
                  as="div"
                  minHeight="300px"
                  width={['100%']}
                  maxWidth="740px"
                >
                  { faceLivenessAnalysis && faceLivenessAnalysis.confidence ? (
                      <ReferenceImage faceLivenessAnalysis={faceLivenessAnalysis} tryagain={tryagain}></ReferenceImage>
                    ) :
                      (<FaceLiveness faceLivenessAnalysis={getfaceLivenessAnalysis} />)
                    
                  }
                </View>
              </Card>
            </Flex>
          </View>
        </Flex>
    </ThemeProvider>


  );
}

export default App;
