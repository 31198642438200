import React from "react";
import '@aws-amplify/ui-react/styles.css';

import {
    Alert,
    Flex,
    Image,
    useTheme,
    Button
} from '@aws-amplify/ui-react';

function ReferenceImage({ faceLivenessAnalysis, tryagain }) {
    const { tokens } = useTheme();

    return (
        <>
            <Image
                src={faceLivenessAnalysis.full_image}
                width="100%"
                height="100%"
                objectFit="cover"
                objectPosition="50% 50%"
            />

            <Alert
                marginTop={tokens.space.large}
                variation="info"
                isDismissible={false}
                hasIcon={false}
            >
                Status: {faceLivenessAnalysis.status}
            </Alert>
            <Alert
                variation="info"
                isDismissible={false}
                hasIcon={false}
            >
                Confidence Score: {faceLivenessAnalysis.confidence.toFixed(2)}%
            </Alert>

            <Flex
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="flex-start"
                wrap="nowrap"
                gap="1rem"
              >
                <Button variation="primary" type="button" marginTop={tokens.space.large} marginBottom={0} onClick={tryagain}>Retake Liveness Test</Button>
            </Flex>
        </>
    );
}

export default ReferenceImage;
